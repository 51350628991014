export default [
  {
    path: "/storeSet",
    name: "storeSet",
    component: () => import("@/views/set/storeSet.vue"),
    meta: {
      name: "门店设置",
    },
  },
  {
    path: "/oftenSet",
    name: "oftenSet",
    component: () => import("@/views/set/oftenSet.vue"),
    meta: {
      name: "常用设置",
    },
  },
  {
    path: "/staffSet",
    name: "staffSet",
    component: () => import("@/views/set/staffSet.vue"),
    meta: {
      name: "员工设置",
    },
  },
  {
    path: "/attendance",
    name: "attendance",
    component: () => import("@/views/set/attendance.vue"),
    meta: {
      name: "出勤管理",
    },
  },
  {
    path: "/projectSet",
    name: "projectSet",
    component: () => import("@/views/set/projectSet.vue"),
    meta: {
      name: "项目设置",
    },
  },
  {
    path: "/goodsSet",
    name: "goodsSet",
    component: () => import("@/views/set/goodsSet.vue"),
    meta: {
      name: "商品设置",
    },
  },
  {
    path: "/cardSet",
    name: "cardSet",
    component: () => import("@/views/set/cardSet.vue"),
    meta: {
      name: "次卡设置",
    },
  },
  {
    path: "/mealSet",
    name: "mealSet",
    component: () => import("@/views/set/mealSet.vue"),
    meta: {
      name: "套餐设置",
    },
  },
  {
    path: "/Commission",
    name: "Commission",
    component: () => import("@/views/set/Commission.vue"),
    meta: {
      name: "提成设置",
    },
  },
  {
    path: "/staffManageSet",
    name: "staffManageSet",
    component: () => import("@/views/set/staffManageSet.vue"),
    meta: {
      name: "常用设置",
    },
  },
  {
    path: "/smsSet",
    name: "smsSet",
    component: () => import("@/views/set/smsSet.vue"),
    meta: {
      name: "短信设置",
    },
  },
];
