/* 开单的tab数据 */

export default {
  state: {
    tab: [
      {
        id: 0,
        name: "项目",
        data: [],
      },
      {
        id: 0,
        name: "产品",
        data: [
          // { id: 0, name: "常用1", data: [{ id: 0, ping:"o",name: "欧莱雅" ,num:"1111",price:"888"}] },
          // { id: 1, name: "洗吹2", data: [{ id: 0, ping:"q",name: "清扬" ,num:"222222",price:"500"},{ id: 1, ping:"d",name: "德芙" ,num:"3333",price:"888"},{ id: 2, ping:"p",name: "飘柔" ,num:"56165",price:"888"}] },
          // { id: 2, name: "护发3", data: [{ id: 0, ping:"h",name: "海飞丝" ,num:"45324",price:"888"},{ id: 1, ping:"l",name: "拉芳" ,num:"15",price:"888"},{ id: 2, ping:"h",name: "好迪" ,num:"45656",price:"888"}] },
          // { id: 3, name: "洗剪吹4", data: [{ id: 0, ping:"b",name: "霸王" ,num:"25645",price:"1000"}] },
        ],
      },
      {
        id: 0,
        name: "预约",
        data: [],
      },
    ],
  },
  mutations: {
    setTab(state, val) {
      state.tab = val;
    },
  },
  actions: {},
  getters: {},
};
