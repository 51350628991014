/* 左侧导航栏 */

export default {
  state: {
    list: [
      {
        id: 0,
        icon: "el-icon-cpu",
        name: "系统管理 ",
        item: [{ name: "系统首页", path: "/index", status: true }],
      },
      {
        id: 1,
        icon: "el-icon-location",
        name: "收银管理 ",
        item: [
          { name: "挂单收银", path: "/home", status: true },
          { name: "售卡管理", path: "/cards", status: true },
        ],
      },
      {
        id: 2,
        icon: "el-icon-setting",
        name: "服务与商品",
        item: [
          { name: "门店设置", path: "/storeSet", status: true },
          { name: "常用设置", path: "/oftenSet", status: true },
          { name: "项目设置", path: "/projectSet", status: true },
          { name: "商品设置", path: "/goodsSet", status: true },
          { name: "套餐设置", path: "/mealSet", status: true },
          { name: "次卡设置", path: "/cardSet", status: true },
          { name: "提成设置", path: "/Commission", status: true },
          { name: "短信设置", path: "/smsSet", status: true },
        ],
      },
      {
        id: 3,
        icon: "el-icon-user",
        name: "员工管理",
        item: [
          { name: "员工设置", path: "/staffSet", status: true },
          { name: "出勤管理", path: "/attendance", status: true },
          { name: "常用设置", path: "/staffManageSet", status: true },
        ],
      },
      {
        id: 4,
        icon: "el-icon-user",
        name: "会员管理",
        item: [
          { name: "会员设置", path: "/userIndex", status: true },
          { name: "常用设置", path: "/vipCardSet", status: true },
        ],
      },
      {
        id: 5,
        icon: "el-icon-s-promotion",
        name: "预约中心",
        item: [{ name: "预约管理", path: "/reservation", status: true }],
      },
      {
        id: 6,
        icon: "el-icon-guide",
        name: "营销拓客",
        item: [
          { name: "推广管理", path: "/promoter", status: true },
          { name: "特价秒杀", path: "/seckill", status: true },
          { name: "多人拼团", path: "/group", status: true },
          { name: "拼团列表", path: "/user_group", status: true },
          { name: "小程序会员", path: "/wei_user", status: true },
          { name: "员工订单统计", path: "/share_group", status: true },
          // { name: "活动主屏(旧)", path: "/activity", status: true },
          { name: "活动主屏", path: "/activity_new", status: false },
          { name: "活动统计", path: "/chain_activity", status: false },
          { name: "投票活动", path: "/vote", status: true },
        ],
      },
      {
        id: 7,
        icon: "el-icon-s-data",
        name: "数据报表",
        item: [
          { name: "数据概览", path: "/data_report", status: true },
          { name: "员工业绩", path: "/staff_report", status: true },
          { name: "收支统计", path: "/shop_finance", status: true },
          { name: "手工提成", path: "/manual_report", status: true },
        ],
      },
      {
        id: 8,
        icon: "el-icon-document-copy",
        name: "订单管理",
        item: [
          { name: "订单列表", path: "/order", status: true },
          { name: "快速核销", path: "/writeoff", status: true },
          { name: "微店订单", path: "/wei", status: true },
        ],
      },
    ],
    tabIndex: 0, //选中
    status:false,//导航是否展开
    topStatus:true,//头部导航是否显示
  },
  mutations: {
    /* 更新导航数据 */
    updateList(state, val) {
      state.list = val;
    },
    /* 设置导航下标 */
    setTabIndex(state, val) {
      state.tabIndex = val;
    },
    setStatus(state, val) {
      state.status = val;
    },
    setTopStatus(state, val) {
      state.topStatus = val;
    },
  },
  actions: {},
  getters: {},
};
