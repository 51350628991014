<template>
  <div class="nav">
    <div class="logo" :class="!isCollapses ? '' : 'logos'">
      <!-- <span v-if="!isCollapses"></span> -->
      <div class="logo-img" v-if="!isCollapses">
        <div>
          <img
            v-if="shopData.photo"
            :src="shopData.photo"
            :alt="shopData.name"
          />
          <img
            v-if="!shopData.photo"
            src="../assets/none.png"
            :alt="shopData.name"
          />
        </div>
        <div>
          <span>{{ shopData.name }}</span>
        </div>
      </div>
      <!-- <span v-if="isCollapses">智</span> -->
      <div class="logo-img" v-if="isCollapses">
        <div>
          <img
            v-if="shopData.photo"
            :src="shopData.photo"
            :alt="shopData.name"
          />
          <img
            v-if="!shopData.photo"
            src="../assets/none.png"
            :alt="shopData.name"
          />
        </div>
      </div>
    </div>
    <el-menu
      :default-active="path"
      class="el-menu-vertical-demo bg"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapses"
      background-color="#8088a8"
      text-color="#fff"
      active-text-color="#409EFF"
    >
      <!--  :router="routers" -->
      <!-- 不展开 -->
      <div v-if="!Status">
        <el-menu-item
          :index="index + ''"
          v-for="(item, index) in list"
          :key="index"
          class="submenus"
          :class="index == indexs ? 'nav-actives' : ''"
          @click="handleOpen(index, item.item[0].path)"
        >
          <i
            :class="index == indexs ? item.icon + ' colors' : item.icon"
            class="color"
          ></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
      <!-- 展开 -->
      <div v-if="Status">
        <el-submenu
          :index="index + ''"
          v-for="(item, index) in list"
          :key="index"
          class="submenus"
          :class="index == indexs ? 'nav-actives' : ''"
        >
          <template slot="title">
            <i :class="item.icon" class="color"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item
            class="bg"
            :index="items.path"
            v-for="(items, indexs) in item.item"
            :key="indexs"
          >
            {{ items.name }}
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<style>
.el-menu {
  border: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

/* .el-submenu__title {
  padding-left: 0 !important;
} */
.logo {
  width: 100%;
  height: 150px;
  font-size: 25px;
  font-weight: 800;
  /* background: red; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logos {
  width: 100%;
  height: 70px;
}
.logo-img {
  width: 100%;
  height: 100%;
}
.logo-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.logos .logo-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.logo-img div:first-child {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logos .logo-img div:first-child {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-img div:last-child {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<script>
export default {
  name: "navs",
  data() {
    return {
      isCollapse: true,
      routers: true,
      path: "",
      indexs: 0, //选中的栏目
      shopData: "", //门店信息
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.indexs = key - 0;
      this.$store.commit("setTabIndex", this.indexs);
      this.$router.push({path: keyPath});
      sessionStorage.setItem("tabIndex", this.indexs + 1);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
      this.indexs = key - 0;
      this.$store.commit("setTabIndex", this.indexs);
    },
    get_shop() {
      let shop = JSON.parse(sessionStorage.getItem("init"));
      shop.shop.role_name = "";
      this.shopData = shop.shop;
      let roles = shop.roles;
      let len = roles.length;
      for (let i = 0; i < len; i++) {
        if (shop.staff.role_id == roles[i].role_id) {
          this.shopData.role_name = roles[i].name;
        }
      }
      console.log('nav.vue店铺基本信息：', this.shopData);

    },
    /**
     * 根据用户基础数据里面的两个参数判断单店活动跟连锁店活动页面是否显示
     */
    getActivityBegin() {
      this.$axios({
        href: "/api/app/meiye/home/init",
      }).then((res) => {
        // console.log('userInitRes', res);
        const activity_shop_status = res.data.shop.activity_shop_status;
        const activity_group_status = res.data.shop.activity_group_status;
        const list = this.$store.state.navmenu.list;
        const listLen = list.length;
        for (let i = 0; i < listLen; i++) {
          if (list[i].name === '营销拓客') {
            const item = list[i].item;
            const itemLen = item.length;
            for (let j = 0; j < itemLen; j++) {
              if (item[j].name === '活动主屏') {
                if (activity_shop_status === 1) {
                  this.$store.state.navmenu.list[i].item[j].status = true;
                } else {
                  this.$store.state.navmenu.list[i].item[j].status = false;
                }
              }
              if (item[j].name === '活动统计') {
                if (activity_group_status === 1) {
                  this.$store.state.navmenu.list[i].item[j].status = true;
                } else {
                  this.$store.state.navmenu.list[i].item[j].status = false;
                }
              }
            }
            break;
          }
        }
      });
    }
  },
  created() {
    this.isCollapse = this.$store.state.navStatus;
    // console.log(this.isCollapse, this.$route.path);
    this.path = this.$route.path;
    this.indexs = sessionStorage.getItem("tabIndex")
      ? sessionStorage.getItem("tabIndex") - 1
      : 0;
    this.$store.commit("setTabIndex", this.indexs);
    this.get_shop();
    this.getActivityBegin();
  },
  computed: {
    isCollapses() {
      return this.$store.state.navStatus;
    },
    list() {
      return this.$store.state.navmenu.list;
    },
    Status(){
       return this.$store.state.navmenu.status;
    }
  },
  watch: {
    $route(to) {
      // console.log(to, 3333333333333333333333333);
      this.path = to.path;
    },
  },
};
</script>

<style moudle>
.el-menu {
  border: none !important;
}
.nav .el-menu-item,
.el-submenu__title {
  font-size: 16px !important;
  font-weight: Normal !important;
}
/* .nav .el-menu-item, .el-submenu__title i {
  color: #fff !important;
} */
.nav .el-menu-item,
.el-submenu__title {
  padding: 0 50px;
}
.nav .color {
  margin-right: 5px;
  color: #fff;
}
.nav .colors {
  margin-right: 5px;
  color: #409eff;
}
.nav-actives {
  background: #fff !important;
  color: #409eff !important;
}
/* .nav-actives .el-menu-item i {
   color: #409eff  !important;
} */

.nav .nav-actives .el-submenu__title {
  background: #6f7799 !important;
  color: #409eff;
}
/* .nav .el-menu-item i {
  color: #fff;
} */
</style>
