<template>
  <div class="box">
    <div class="box-left" :style="{ width: isCollapse ? '230px' : 'auto' }">
      <!-- 侧边菜单 -->
      <div class="box-nav">
        <navs></navs>
      </div>
      <!-- 侧边菜单 -->
    </div>

    <div class="box-right">
      <!-- 头部导航 -->
      <div
        class="box-head"
        :style="{ height: !tabStatuss ? '5vh' : '', transition: 'all 0.5s' }"
      >
        <div
          class="box-head-nav"
          :style="{ height: !tabStatuss ? '100%' : '' }"
        >
          <div class="box-head-nav-left">
            <el-button
              type="text"
              style="color:#fff;"
              @click="isCollapses"
              :icon="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
              circle
            ></el-button>
            <img src="../assets/logo-w.png" alt="智能门店+" @dblclick="sets" />
          </div>
          <div class="box-head-nav-right">
            <el-menu class="el-menu-demo" mode="horizontal">
              <el-submenu index="2">
                <template slot="title">
                  <img
                    :src="shopData.photo"
                    :alt="shopData.manager"
                    class="img"
                  />
                  <span class="shop-name">
                    {{ shopData.manager }}({{ shopData.role_name }})
                  </span>
                </template>
                <el-menu-item index="2-2" @click="outs">安全退出</el-menu-item>
              </el-submenu>
              <!-- <el-menu-item index="3">消息中心</el-menu-item> -->
            </el-menu>
          </div>
        </div>
        <!-- 切换路由页面tab -->
        <div class="box-head-tab" v-if="0">
          <div class="tags" v-if="showTags">
            <div id="tags-view-container" class="tags-view-container">
              <tabs ref="scrollPane" class="tags-view-wrapper">
                <router-link
                  v-for="(tag, index) in tagsList"
                  ref="tag"
                  :key="tag.path"
                  :class="{ active: isActive(tag.path) }"
                  :to="{
                    path: tag.path,
                    query: tag.query,
                    fullPath: tag.fullPath,
                  }"
                  tag="span"
                  @click.middle.native="closeTags(index)"
                  class="tags-view-item"
                >
                  {{ tag.title }}
                  <span
                    class="el-icon-close"
                    @click.prevent.stop="closeTags(index)"
                  />
                </router-link>
              </tabs>
            </div>

            <!-- 其他操作按钮 -->
            <div class="tags-close-box">
              <el-dropdown @command="handleTags">
                <el-button size="mini">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu size="small" slot="dropdown">
                  <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                  <el-dropdown-item command="all">关闭所有</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="box-head-tab" v-if="getSetData(2).status">
          <!-- {{ getTab }} -->

          <!-- <el-tabs type="card" @tab-click="handleClick">
            <el-tab-pane
              :key="index"
              v-for="(item, index) in getTab"
              :label="item.name"
              :name="item.name"
            >
            </el-tab-pane>
          </el-tabs> -->

          <div id="tags-view-container" class="tags-view-container">
            <tabs ref="scrollPane" class="tags-view-wrapper">
              <router-link
                v-for="(tag, index) in getTab"
                ref="tag"
                :key="tag.path"
                :class="{ active: isActive(tag.path) }"
                :to="{
                  path: tag.path,
                  query: tag.query,
                  fullPath: tag.fullPath,
                }"
                tag="span"
                @click.middle.native="closeTags(index)"
                class="tags-view-item"
              >
                {{ tag.name }}
                <!-- <span
                    class="el-icon-close"
                    @click.prevent.stop="closeTags(index)"
                  /> -->
              </router-link>
            </tabs>
          </div>
        </div>
        <!-- 切换路由页面tab -->
      </div>
      <!-- 头部导航 -->
      <!-- 中间内容 -->
      <div class="box-body" :style="{ height: !tabStatuss ? '95vh' : '' }">
        <!-- 内容 -->
        <div class="box-body-cont">
          <div class="box-body-cont-box">
            <slot><!-- 我是路由内容 --></slot>
          </div>
        </div>
        <!-- 内容 -->
      </div>
      <!-- 中间内容 -->
      <!-- 底部版权 -->
      <!-- <div class="box-foot">我是底部</div> -->
      <!-- 底部版权 -->

      <!-- 配置系统设置 -->
      <el-dialog
        title="以下内容请谨慎修改，此内容包含开发实验中的功能，可能会出现异常错误。"
        :visible.sync="setStatus"
        width="800px"
      >
        <el-form ref="form" label-width="80px">
          <el-form-item label="左侧导航:" style="width:300px">
            <el-switch
              v-model="getSetData(1).status"
              active-text="显示"
              @change="setSetStatus"
              inactive-text="关闭"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="头部导航:" style="width:300px">
            <el-switch
              v-model="getSetData(2).status"
              active-text="显示"
              @change="setSetTopStatus"
              inactive-text="关闭"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="活动主屏:" style="width:300px">
            <el-switch
              v-model="getSetData().status"
              @change="setSetLset"
              active-text="显示"
              inactive-text="关闭"
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setStatus = false">取 消</el-button>
          <el-button type="primary" @click="setStatus = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 配置系统设置 -->
    </div>
  </div>
</template>

<script>
import tabs from "./tabs.vue";
import navs from "./nav.vue";
export default {
  name: "box",
  components: { tabs, navs },
  data() {
    return {
      isCollapse: true,
      tabStatus: true,
      tagsList: [],
      shopData: "", //门店信息
      tabIndex: 0, //路由下标
      setStatus: false, //显示系统配置
    };
  },
  methods: {
    /* 路由下标 */
    handleClick(tab) {
      this.tabIndex = tab.index - 0;
      this.$router.push({
        path: this.getTab[this.tabIndex].path,
      });
    },
    get_shop() {
      let shop = JSON.parse(sessionStorage.getItem("init"));
      shop.shop.role_name = "";
      this.shopData = shop.shop;
      let roles = shop.roles;
      let len = roles.length;

      for (let i = 0; i < len; i++) {
        if (shop.staff.role_id == roles[i].role_id) {
          this.shopData.role_name = roles[i].name;
        }
      }
      console.log('box.vue店铺基本信息：', this.shopData);
    },
    outs() {
      this.$confirm("此操作将退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("token");
          this.$router.replace({
            path: "/",
          });
        })
        .catch(() => {});
    },
    isCollapses() {
      this.isCollapse = !this.isCollapse;
      this.$store.commit("nav_status");
    },
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path);

        this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
      } else {
        this.$router.push("/");
        /* 删除最后一个同时删掉缓存 */
        sessionStorage.removeItem("Tags");
      }
    },
    isActive(path) {
      // console.log(this.$route.fullPath);
      return path === this.$route.fullPath;
    },
    // 关闭全部标签
    closeAll() {
      this.tagsList = [];
      this.$router.push("/");
      sessionStorage.removeItem("Tags");
      this.$store.commit("tab_status");
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter((item) => {
        return item.path === this.$route.fullPath;
      });
      this.tagsList = curItem;

      sessionStorage.setItem("Tags", JSON.stringify(this.tagsList));
    },
    handleTags(command) {
      command === "other" ? this.closeOther() : this.closeAll();
    },
    // 设置标签
    setTags(route) {
      if (!route.meta.NoTabPage) {
        const isExist = this.tagsList.some((item) => {
          return item.path === route.fullPath;
        });
        !isExist &&
          this.tagsList.push({
            title: route.meta.name,
            path: route.fullPath,
            // name: route.matched[1].components.default.name
          });
      }
    },
    /* 配置系统设置 */
    sets() {
      let token = sessionStorage.getItem("token") || "";
      this.$prompt("请输入密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (value != token) {
            this.$message.error("对不起，秘密错误~");
            return false;
          }
          this.setStatus = true;
        })
        .catch(() => {});
    },
    getSetData(obj) {
      if (!obj) {
        return this.$store.state.navmenu.list[6].item[6];
      }
      if (obj == 1) {
        return { status: this.$store.state.navmenu.status };
      }
      if (obj == 2) {
        return { status: this.$store.state.navmenu.topStatus };
      }
    },
    setSetLset() {
      this.$message({
        message: "修改成功,如果出现异常请刷新页面即可",
        type: "success",
      });
      // this.$store.commit("setStatus", obj);
    },
    setSetStatus(obj) {
      this.$message({
        message: "修改成功,如果出现异常请刷新页面即可",
        type: "success",
      });
      this.$store.commit("setStatus", obj);
    },
    setSetTopStatus(obj) {
      this.$message({
        message: "修改成功,如果出现异常请刷新页面即可",
        type: "success",
      });
      this.$store.commit("setTopStatus", obj);
    },
  },
  mounted() {
    console.log(this.$route);

    var tags = sessionStorage.getItem("Tags")
      ? JSON.parse(sessionStorage.getItem("Tags"))
      : [];

    if (tags && tags.length > 0) {
      this.tagsList = tags;
    }
  },
  created() {
    this.tabStatus = this.$store.state.tabStatus;
    this.setTags(this.$route);
    this.get_shop();
  },
  computed: {
    tabStatuss() {
      return this.$store.state.tabStatus;
    },
    showTags() {
      if (this.tagsList.length > 1) {
        this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
      }
      return this.$store.state.tabStatus;
    },
    getTab() {
      let arr = [];
      let d = this.$store.state.navmenu.list[this.$store.state.navmenu.tabIndex]
        .item;
      for (let item of d) {
        // console.log(i,d[i])
        if (item.status) {
          arr.push(item);
        }
      }
      return arr;
    },
  },
  watch: {
    // 对router进行监听，每当访问router时，对tags的进行修改
    $route: async function(newValue) {
      this.setTags(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  &-left {
    width: 230px;
    height: 100vh;
    border-right: solid 1px #e6e6e6;
    overflow-x: auto;
    background: #8088a8;
    color: #fff;
  }
  &-right {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &-head {
    width: 100%;
    height: 116px;
    background: #8088a8;
    border-bottom: 1px solid #e6e6e6;
    &-nav {
      width: 100%;
      height: 70px;
      // background: #767e9e;
      // background: red;
      display: flex;
      &-left {
        width: 20%;
        height: 100%;
        padding: 5px;
        // background: #8088a8;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;

        button {
          box-sizing: border-box;
          font-size: 20px;
        }
      }
      &-right {
        width: 80%;
        height: 100%;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    &-tab {
      // background: rgb(24, 156, 94);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // padding: 10px;
      box-sizing: border-box;
      width: 100%;
      // height: 6vh;
      overflow-x: auto;
      overflow-y: hidden;
      background: #fff;
    }
  }

  &-body {
    width: 100%;
    height: 85vh;
    background: #f4f8fb;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    &-cont {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      position: relative;
      &-box {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        position: absolute;
        top: 0;
        &-left {
          left: 20%;
          border-radius: 50px;
          opacity: 0;
        }
        &-right {
          left: 0;
          border-radius: 0px;
          opacity: 1;
        }
      }
    }
  }
  &-foot {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-nav {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

// .tags-view-item {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 10px;
//   padding: 5px 10px;
//   background: #fff;
//   border: 1px solid #dcdfe6;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   font-size: 15px;
//   transition: all 0.3s;
//   cursor: pointer;
// }
// .tags-view-item:hover {
//   color: #409eff;
//   background: #ecf5ff;
//   border-color: #b3d8ff;
// }
// .active {
//   color: #fff;
//   background-color: #409eff;
//   border-color: #409eff;
// }
.shop-info {
  width: 161px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
.shop-name {
  width: 112px;
  height: 21px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: Bold;
  text-align: left;
  // color: #333333;
  color: #fff;
  line-height: 6px;
  margin-left: 9px;
}
</style>

<style>
/* .el-scrollbar__view {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  background: #eee;
}
.el-icon-close {
  padding: 1px;
  border-radius: 50%;
  margin-left: 5px;
  transition: all 0.5s;
}
.el-icon-close:hover {
  background-color: #c0c4cc;
  color: #fff;
} */
</style>

<style>
.box-head-nav .el-menu {
  background: rgba(0, 0, 0, 0);
}
.box-head-nav .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: #6f7799 !important;
}
.box-head-nav .el-submenu__title i {
  color: #fff;
}
.box-head-tab .el-scrollbar__view {
  display: flex;
  padding: 6px 0;
}
.menu-collapsed .el-icon-arrow-right:before {
  display: none;
}

.tags {
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  background: #f0f0f0;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: none;
}

.tags-li {
  float: left;
  margin: 3px 5px 2px 3px;
  border-radius: 3px;
  font-size: 13px;
  overflow: hidden;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 3px 5px 4px 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li-icon {
  cursor: pointer;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li-title {
  float: left;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
  text-decoration: none;
}

.tags-li.active {
  /*color: #fff;*/
  /*border: 1px solid #10B9D3;*/
  /*background-color: #10B9D3;*/
}

.tags-li.active .tags-li-title {
  /*color: #fff;*/
}

.tags-close-box {
  box-sizing: border-box;
  text-align: center;
  z-index: 10;
  float: right;
  margin-right: 1px;
  line-height: 2;
}
</style>
<style>
/*.logoban{*/
/*width: auto !important;*/
/*}*/
.news-dialog {
  background: #fff;
  z-index: 3000 !important;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 260px;
  top: 60px !important;
  left: 0 !important;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  -webkit-transform: translate(100%);
  z-index: 40000;
  left: auto !important;
  right: 0 !important;
  transform: translate(0);
}

.news-dialog .el-dialog {
  margin: auto !important;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: none;
  width: 100%;
}

.news-dialog.show {
  transform: translate(0);
}

.tag-new {
  width: 100%;
  margin: 10px 0;
}

@media screen and (max-width: 680px) {
  .collapsedLogo {
    display: none;
  }

  .el-dialog {
    width: 90% !important;
  }

  .content-expanded {
    max-width: 100% !important;
    max-height: calc(100% - 60px);
  }

  .mobile-ex {
    background: #fff;
    z-index: 3000;
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 260px;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    -webkit-transform: translate(100%);
    z-index: 40000;
    left: auto;
    right: 0;
    transform: translate(100%);
  }

  .mobile-ex.menu-collapsed-mobile {
    transform: translate(0);
  }

  .el-menu--collapse {
    width: 100% !important;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-cascader.el-cascader--medium {
    width: 100% !important;
  }

  .toolbar.roles {
    width: 100% !important;
  }

  .toolbar.perms {
    width: 800px !important;
  }

  .toolbar.perms .box-card {
    width: 100% !important;
  }

  .login-container {
    width: 300px !important;
  }

  .count-test label {
  }

  .content-wrapper .tags {
    margin: 0px;
    padding: 0px;
  }
}
@media screen and (max-width: 1250px) {
  .box-left {
    /* width: 50%; */
    transition: all 0.5s;
  }
}
</style>

<style>
.tags-view-container {
  /* height: 55px; */
  /* width: calc(100% - 60px); */
  width: calc(100% - 200px);
  /*background: #fff;*/
  /*border-bottom: 1px solid #d8dce5;*/
  /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);*/
  float: left;
}

.tags-view-container .tags-view-wrapper .tags-view-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 33px;
  line-height: 33px;
  border: 1px solid #d8dce5;
  border-right: 1px solid #d8dce5;
  border-top: 1px solid #d8dce5;
  color: #495060;
  background: #fff;
  padding: 0 16px;
  font-size: 14px;
  margin-left: 5px;
  /* margin-top: 4px; */
  transition: all 0.3s;
}
/* .tags-view-container .tags-view-wrapper .tags-view-item:nth-of-type(1){
  border-left: 1px solid #d8dce5;
} */
.tags-view-container .tags-view-wrapper .tags-view-item:hover {
  color: #409eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}

.tags-view-container .tags-view-wrapper .tags-view-item:first-of-type {
  margin-left: 15px;
}

.tags-view-container .tags-view-wrapper .tags-view-item:last-of-type {
  margin-right: 15px;
}

.tags-view-container .tags-view-wrapper .tags-view-item.active {
  /*background-color: #42b983;*/
  /*color: #fff;*/
  /*border-color: #42b983;*/
}

.tags-view-container .tags-view-wrapper .tags-view-item.active::before {
  content: "";
  background: #2d8cf0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}

.tags-view-container .contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.tags-view-container .contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.tags-view-container .contextmenu li:hover {
  background: #eee;
}
</style>

<style>
.tags-view-wrapper .tags-view-item .el-icon-close {
  width: 16px;
  height: 16px;
  vertical-align: 2px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: 100% 50%;
}

.tags-view-wrapper .tags-view-item .el-icon-close:before {
  transform: scale(0.6);
  display: inline-block;
  vertical-align: -3px;
}

.tags-view-wrapper .tags-view-item .el-icon-close:hover {
  background-color: #ef2b74;
  color: #fff;
}
</style>

<style moudle>
/* .el-menu--horizontal>.el-submenu .el-submenu__title {
    height: 48px !important;
    line-height: 45px !important;
} */
/* .el-menus{
  background: #8088a8 !important;
  color: #fff !important;
} */
.box-head-tab .el-tabs__header {
  margin: 0;
}
</style>
