<template>
  <div id="app">
    <box v-if="!login">
      <transition :name="transitionName">
        <router-view />
      </transition>
    </box>
    <div class="login" v-if="login">
      <router-view />
    </div>
  </div>
</template>

<script>
import box from "@/components/box.vue";
export default {
  components: {
    box,
  },
  data() {
    return {
      transitionName: "",
      login: true,
      browse: "", // 浏览器
    };
  },
  methods: {
    /**
     * 获取浏览器信息
     */
    getBrowserInfo() {
      const agent = window.navigator.userAgent.toLowerCase(); // 判断登陆端是pc还是手机
      console.log("agent:", agent);
      let arr = [];
      const system = agent
        .split(" ")[1]
        .split(" ")[0]
        .split("(")[1];
      console.log("system:", system);
      arr.push(system);
      /**
       * [...]: 匹配 [...] 中的所有字符
       * .: 匹配除 \n 之外的任何单个字符
       * \n: 匹配一个换行符
       * \d: 匹配数字
       * \s: 匹配空白字符(可能是空格、制表符、换页符、其他空白)，等价于[\f\n\r\t\v]
       * gi: 全局匹配 + 忽略大小写
       */
      const REGSTR_EDGE = /edge\/[\d.]+/gi; // 正则表达式，验证Edge浏览器
      const REGSTR_IE = /trident\/[\d.]+/gi; // 正则表达式，验证IE(4~11)浏览器
      const OLD_IE = /msie\s[\d.]+/gi; // 正则表达式，验证IE浏览器(用 "msie x.0" 来判断IE版本并不准确)
      const REGSTR_FF = /firefox\/[\d.]+/gi; // 正则表达式，验证FireFox浏览器
      const REGSTR_CHROME = /chrome\/[\d.]+/gi; // 正则表达式，验证Chrome浏览器
      const REGSTR_SAF = /safari\/[\d.]+/gi; // 正则表达式，验证Safari浏览器
      const REGSTR_OPERA = /opr\/[\d.]+/gi; // 正则表达式，验证Opera浏览器
      // IE
      if (agent.indexOf("trident") > 0) {
        arr.push(agent.match(REGSTR_IE)[0].split("/")[0]);
        arr.push(agent.match(REGSTR_IE)[0].split("/")[1]);
        return arr;
      }
      // OLD_IE
      if (agent.indexOf("msie") > 0) {
        arr.push(agent.match(OLD_IE)[0].split(" ")[0]);
        arr.push(agent.match(OLD_IE)[0].split(" ")[1]);
        return arr;
      }
      // Edge
      if (agent.indexOf("edge") > 0) {
        arr.push(agent.match(REGSTR_EDGE)[0].split("/")[0]);
        arr.push(agent.match(REGSTR_EDGE)[0].split("/")[1]);
        return arr;
      }
      // FireFox
      if (agent.indexOf("firefox") > 0) {
        arr.push(agent.match(REGSTR_FF)[0].split("/")[0]);
        arr.push(agent.match(REGSTR_FF)[0].split("/")[1]);
        return arr;
      }
      // Opera
      if (agent.indexOf("opr") > 0) {
        arr.push(agent.match(REGSTR_OPERA)[0].split("/")[0]);
        arr.push(agent.match(REGSTR_OPERA)[0].split("/")[1]);
        return arr;
      }
      // Safari
      if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
        arr.push(agent.match(REGSTR_SAF)[0].split("/")[0]);
        arr.push(agent.match(REGSTR_SAF)[0].split("/")[1]);
        return arr;
      }
      // Chrome
      if (agent.indexOf("chrome") > 0) {
        arr.push(agent.match(REGSTR_CHROME)[0].split("/")[0]);
        arr.push(agent.match(REGSTR_CHROME)[0].split("/")[1]);
        return arr;
      } else {
        arr.push("未获取到浏览器信息");
        return arr;
      }
    },
    /**
     * 获取浏览器名称以及版本
     */
    getBrowseName() {
      const browserInfo = this.getBrowserInfo();
      if (browserInfo[1] && browserInfo[2]) {
        this.browse = this.getBrowserInfo()[1] + " " + this.getBrowserInfo()[2];
      } else {
        this.browse = "未获取到浏览器信息";
      }
      switch (this.browse) {
        case "msie 6.0":
          this.browse = "IE 6";
          break;
        case "msie 7.0":
          this.browse = "IE 7";
          break;
        case "trident 4.0":
          this.browse = "IE 8";
          break;
        case "trident 5.0":
          this.browse = "IE 9";
          break;
        case "trident 6.0":
          this.browse = "IE 10";
          break;
        case "trident 7.0":
          this.browse = "IE 11";
          break;
      }
      console.log("browse:", this.browse)
    },
    /**
     * 用户当前的浏览器版本不符合期望，弹出系统提示
     */
    systemTip(obj, url) {
      this.$confirm(obj, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        if (url) {
          window.open(url);
        }
      })
      .catch(() => {
        // window.open(url);
      });
    },
  },
  watch: {
    $route(to) {
      console.log(to);
      window.document.title = "智能门店+ -" + to.meta.name;
      if (to.path == "/") {
        this.login = true;
      } else {
        this.login = false;
      }
      let _this = this;
      this.transitionName = "slide-left";
      setTimeout(() => {
        _this.transitionName = "slide-right";
      }, 500);
    },
  },
  created() {
    // 获取浏览器名称以及版本
    this.getBrowseName();
    // IE
    if (this.browse.includes("IE")) {
      this.systemTip(
        `当前浏览器内核版本为：${this.browse}，由于当期浏览器版本过低，继续使用该版本浏览器会导致显示异常或者存在系统漏洞。如果是360浏览器，请使用360浏览器极速模式，如果不是请下载或更新360浏览器到最新版本，点击确认前去下载更新`,
        "https://browser.360.cn/"
      );
      return false;
    }
    // Chrome
    if (this.browse.includes("chrome")) {
      let num = this.browse.split(" ")[1].split(".")[0];
      if (num < 78) {
        this.systemTip(
          `当前浏览器内核版本为：${this.browse}，由于当期浏览器版本过低，继续使用该版本浏览器会导致显示异常或者存在系统漏洞。点击确认前去下载更新`,
          "https://www.google.cn/chrome/"
        );
      }
      return false;
    }
    // Safari
    if (this.browse.includes("Safari")) {
      let num = this.browse.split(" ")[1].split(".")[0];
      if (num < 600) {
        this.systemTip(
          `当前浏览器内核版本为：${this.browse}，由于当期浏览器版本过低，继续使用该版本浏览器会导致显示异常或者存在系统漏洞。请打开应用市场更新系统版本`,
          "https://support.apple.com/zh-cn/HT204416"
        );
      }
      return false;
    }
    // FireFox
    if (this.browse.includes("firefox")) {
      let num = this.browse.split(" ")[1].split(".")[0];
      if (num < 83) {
        this.systemTip(
          `当前浏览器内核版本为：${this.browse}，由于当期浏览器版本过低，继续使用该版本浏览器会导致显示异常或者存在系统漏洞。点击确认前去下载更新`,
          "https://www.firefox.com.cn/"
        );
      }
      return false;
    }

    this.systemTip(
      `当前浏览器内核版本为：${this.browse}，我们强烈推荐使用谷歌浏览器，使用谷歌浏览器可将系统效果展示到最极致，点击确认前去下载`,
      "https://www.google.cn/chrome/"
    );
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold, Avenir, Helvetica, Arial, sans-serif;
}

#app {
  font-family: Microsoft YaHei, Microsoft YaHei-Bold, Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  transition: transform 350ms;
  will-change: transform;
}

.slide-right-enter,
.slide-left-leave-active {
  transform: translate(-100%, 0);
}

.slide-right-leave-active,
.slide-left-enter {
  transform: translate(100%, 0);
}

.flip-list-move {
  transition: transform 0.6s;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 0.6s;
}

.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.flip-list-leave-active {
  position: absolute;
}

div::-webkit-scrollbar,
body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

div::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  /* background: #535353; */
  background-color: rgba(144, 147, 153, 0.15);
  border-radius: 5px;
  transition: 0.3s background-color;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
}

div::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  position: relative;
  z-index: 9999999;
  background: #f4f8fb;
  border-radius: 5px;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
}

#edui1_iframeholder {
  width: auto !important;
}
</style>

<style>
/* 全局样式  */
.btn-other-s {
  width: 100px;
  height: 36px;
  border: none;
  background: #8088a8;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Normal;
  font-weight: Normal;
  color: #fff;
  line-height: 20px;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.btn-success {
  width: 116px;
  height: 49px;
  border: none;
  background: #11cf68;
  color: #fff;
  text-shadow: 0px 3px 3px 0px rgba(0, 126, 180, 0.45);
  outline: none;
  cursor: pointer;
}

.btn-info {
  width: 116px;
  height: 49px;
  border: none;
  background: #55bfff;
  color: #fff;
  text-shadow: 0px 3px 3px 0px rgba(0, 126, 180, 0.45);
  cursor: pointer;
  outline: none;
}

.btn-warning {
  width: 116px;
  height: 49px;
  border: none;
  background: #f8b928;
  color: #fff;
  text-shadow: 0px 3px 3px 0px rgba(0, 126, 180, 0.45);
  cursor: pointer;
  outline: none;
}

.btn-danger {
  width: 116px;
  height: 49px;
  border: none;
  background: #ff4846;
  color: #fff;
  text-shadow: 0px 3px 3px 0px rgba(0, 126, 180, 0.45);
  cursor: pointer;
  outline: none;
}

.btn-auto {
  width: auto !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}
</style>
