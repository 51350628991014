export default [
  {
    path: "/reservation",
    name: "reservation",
    component: () => import("@/views/reservation/reservation.vue"),
    meta: {
      name: "预约中心",
    },
  },
];
