export default [
  {
    path: "/data_report",
    name: "data_report",
    component: () => import("@/views/data_report/data_report.vue"),
    meta: {
      name: "数据概览",
    },
  },
  {
    path: "/staff_report",
    name: "staff_report",
    component: () => import("@/views/data_report/staff_report.vue"),
    meta: {
      name: "员工业绩",
    },
  },
  {
    path: "/shop_finance",
    name: "shop_finance",
    component: () => import("@/views/data_report/shop_finance.vue"),
    meta: {
      name: "收支统计",
    },
  },
  {
    path: "/manual_report",
    name: "manual_report",
    component: () => import("@/views/data_report/manual_report.vue"),
    meta: {
      name: "手工提成",
    },
  },
];
