export default [
  {
    path: "/promoter",
    name: "promoter",
    component: () => import("@/views/Marketing/promoter.vue"),
    meta: {
      name: "推广设置",
    },
  },
  {
    path: "/seckill",
    name: "seckill",
    component: () => import("@/views/Marketing/seckill.vue"),
    meta: {
      name: "特价秒杀",
    },
  },
  {
    path: "/group",
    name: "group",
    component: () => import("@/views/Marketing/group.vue"),
    meta: {
      name: "多人拼团",
    },
  },
  {
    path: "/user_group",
    name: "user_group",
    component: () => import("@/views/Marketing/user_group.vue"),
    meta: {
      name: "拼团列表",
    },
  },
  {
    path: "/share_group",
    name: "share_group",
    component: () => import("@/views/Marketing/share_group.vue"),
    meta: {
      name: "员工订单统计",
    },
  },
  {
    path: "/wei_user",
    name: "wei_user",
    component: () => import("@/views/Marketing/wei_user.vue"),
    meta: {
      name: "小程序会员",
    },
  },
  /* {
    path: "/activity",
    name: "activity",
    component: () => import("@/views/Marketing/activity.vue"),
    meta: {
      name: "活动主屏(旧)",
    },
  }, */
  {
    path: "/activity_new",
    name: "activity_new",
    component: () => import("@/views/Marketing/activity_new.vue"),
    meta: {
      name: "活动主屏",
    },
  },
  {
    path: "/chain_activity",
    name: "chain_activity",
    component: () => import("@/views/Marketing/chain_activity.vue"),
    meta: {
      name: "活动统计",
    },
  },
  {
    path: "/vote",
    name: "vote",
    component: () => import("@/views/Marketing/vote.vue"),
    meta: {
      name: "投票活动",
    },
  },
];
