export default [
  {
    path: "/cards",
    name: "cards",
    component: () => import("@/views/index/cards.vue"),
    meta: {
      name: "售卡管理",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      name: "挂单收银",
    },
  },
];
