export default [
  {
    path: "/serchUser",
    name: "serchUser",
    component: () => import("@/views/user/serchUser.vue"),
    meta: {
      name: "会员搜索",
    },
  },
  {
    path: "/userIndex",
    name: "userIndex",
    component: () => import("@/views/user/userIndex.vue"),
    meta: {
      name: "会员设置",
    },
  },
  {
    path: "/vipCardSet",
    name: "vipCardSet",
    component: () => import("@/views/user/vipCardSet.vue"),
    meta: {
      name: "常用设置",
    },
  },
];
