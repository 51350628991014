<template>
  <div class="login">
    <div class="login-box">
      <div class="login-box-l">
        <el-carousel>
          <el-carousel-item v-for="(item,index) in adData" :key="index">
            <img :src="item.img" :alt="item.name">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="login-box-r">
        <div class="login-box-r-box">
          <div class="login-box-r-box-logo">
            <img
              src="@/assets/logo.png"
              alt="智能门店+ ————— 您的门店智能管家"
            />
            <p>您的门店智能管家</p>
          </div>

          <div class="login-box-r-box-cont">
            <div class="login-box-r-box-cont-l">
              <div class="login-box-r-box-cont-l-item">
                <el-input
                  placeholder="请输入手机号码"
                  style="width:100%;height:100%"
                  typ="text"
                  v-model="loginForm.username"
                  @keyup.enter.native="handleLogin"
                >
                  <i slot="prefix" class="el-icon-user"></i>
                </el-input>
              </div>
              <div class="login-box-r-box-cont-l-item">
                <el-input
                  placeholder="请输入密码"
                  type="password"
                  style="width:100%;height:100%"
                  v-model="loginForm.password"
                  @keyup.enter.native="handleLogin"
                >
                  <i slot="prefix" class="el-icon-user"></i>
                </el-input>
              </div>
              <div class="login-box-r-box-cont-l-item flex" v-if="0">
                <el-input
                  placeholder="验证码"
                  style="width: 150px;height: 100%;"
                  type="text"
                  v-model="loginForm.code"
                ></el-input>
                <div class="login-box-r-box-cont-l-item-code">
                  <img src="@/assets/code.png" alt="验证码" />
                </div>
              </div>
              <div class="login-box-r-box-cont-l-item">
                <el-button
                  type="primary"
                  style="width:100%;height:100%"
                  @click="handleLogin"
                >
                  登录
                </el-button>
              </div>
            </div>
            <div class="login-box-r-box-cont-r">
              <div class="login-box-r-box-cont-r-code">
                <img src="@/assets/qr.png" alt="微信扫码登录" />
                <div class="login-box-r-box-cont-r-code-ok" v-if="type">
                  扫码登录成功~
                </div>
                <div class="login-box-r-box-cont-r-code-oks">
                  点击刷新二维码
                </div>
              </div>
              <div class="login-box-r-box-cont-r-text">APP扫码登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adData: [
        {id:1,name:"智能门店＋",path:"",img:require('../assets/ad1.jpg')},
        {id:2,name:"智能门店＋",path:"",img:require('../assets/ad2.jpg')},
        {id:3,name:"智能门店＋",path:"",img:require('../assets/ad3.jpg')},
        {id:4,name:"智能门店＋",path:"",img:require('../assets/ad4.jpg')},
      ], // 广告
      loginForm: {
        username: "",
        password: "",
        code: "",
      }, // 登录参数
      type: false, // 扫码登录标识
    };
  },
  methods: {
    /**
     * 用户点击登录按钮进行登录
     */
    handleLogin() {
      const that = this;
      const loginParameter = {
        phone: this.loginForm.username,
        password: this.loginForm.password
      };
      const phoneRegular = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/; // 验证手机号码正则表达式
      // const passwordRegular = /^[a-zA-Z0-9_]{6,16}$/; // 验证密码正则表达式
      if (!phoneRegular.test(loginParameter.phone)) {
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      /* if (!passwordRegular.test(loginParameter.password)) {
        this.$message.error("请输入字母，数字，下划线组成的6-16位密码密码");
        return false;
      } */
      if (!loginParameter.password) {
        this.$message.error("请输入字母，数字，下划线组成的6-16位密码密码");
        return false;
      }
      this.$axios({
        href: "/api/user/login",
        data: loginParameter,
      }).then((res) => {
        console.log("loginRes:", res);
        that.$message({
          message: "登录成功~",
          type: "success",
        });
        this.getInit(res.data.token);
        sessionStorage.setItem("token", res.data.token); // 将token储存到sessioniStorage里
      });
    },
    /**
     * 获取系统初始化数据
     */
    getInit(obj) {
      this.$axios({
        href: "/api/app/meiye/home/init",
        data: { token: obj },
      }).then((res) => {
        console.log('userInitRes', res);
        this.$router.replace({
          path: "/index",
        });
        sessionStorage.setItem("init", JSON.stringify(res.data));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #8088a8;

  &-box {
    display: flex;
    width: 1140px;
    height: 520px;
    border: 1px solid #777777;
    background: #ffffff;
    text-shadow: 0px 14px 8px 0px rgba(0, 0, 0, 0.15);

    &-l {
      width: 361px;
      height: 100%;
      border-right: 1px solid #777777;
      background: #ececec;
    }

    &-r {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 779px;
      height: 100%;
      background: #ffffff;

      &-box {
        width: 640px;
        height: 428px;
        // background: red;

        &-logo {
          display: flex;
          justify-content: flex-start;
          // align-items: flex-start;
          padding-top: 71px;
          width: 100%;
          height: 172px;
          box-sizing: border-box;

          img {
            width: 180px;
            height: 31px;
          }

          p {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            width: 128px;
            height: 20px;
            margin-left: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Normal;
            font-size: 14px;
            font-weight: Normal;
            color: #aaaaaa;
            letter-spacing: 2px;
          }
        }

        &-cont {
          display: flex;
          width: 640px;
          height: 256px;
          background: #fff;

          &-l {
            width: 390px;
            height: 100%;
            border-right: 1px solid #aaaaaa;
            background: #fff;

            &-item {
              position: relative;
              width: 320px;
              height: 46px;
              margin-bottom: 24px;
              font-size: 14px;

              &-code {
                margin-left: 42px;
                width: 128px;
                height: 40px;
                cursor: pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            &-item:last-child {
              margin-bottom: 0;
            }
          }

          &-r {
            width: 250px;
            height: 100%;
            // background: yellow;

            &-code {
              position: relative;
              width: 180px;
              height: 180px;
              margin: 19px 0 0 70px;
              cursor: pointer;

              &-ok,
              &-oks {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background: rgba(111, 111, 111, 0.8);
                font-family: Microsoft YaHei, Microsoft YaHei-Normal;
                font-size: 16px;
                color: #fff;
              }

              &-oks {
                opacity: 0;
                transition: all 0.5s;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            &-code:hover &-code-oks {
              opacity: 1;
            }
            
            &-text {
              width: 180px;
              height: 22px;
              margin: 16px 0 0 70px;
              font-family: Microsoft YaHei, Microsoft YaHei-Normal;
              font-size: 16px;
              font-weight: Normal;
              color: #aaaaaa;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
}
</style>

<style moudle>
.login .el-input__prefix,
.login .el-input__suffix {
  top: 12px !important;
}

.login .el-input__inner {
  width: 100%;
  line-height: 20px;
  /* background: #f2f2f2; */
  font-family: Microsoft YaHei, Microsoft YaHei-Normal;
  font-size: 14px;
  font-weight: Normal;
  color: #a3aab3;
  text-align: left;
}

.login .el-carousel__item h3 {
  margin: 0;
  line-height: 200px;
  font-size: 14px;
  color: #475669;
  opacity: 0.75;
}

.login .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.login .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.login .el-carousel__container{
  height: 520px !important;
}
</style>
