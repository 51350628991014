import Vue from "vue";
import Vuex from "vuex";
import order from "./home/order";
import tabData from "./home/tabData";
import navmenu from "./navmenu/navmenu";
import publics from "./public/public";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navStatus: false, //左侧导航展开
    tabStatus: true, //路由tab展开
    tagsStoreList: [],
    staff: [], //员工数据
    payStatus: false, //充值界面
    headerClolor: {
      bgcolor: "#f5f7fa", //背景颜色
      width: 80, //id宽度
      border: false, //表格显示格子
      stripe:true,//斑马格子
    }, //表头配置
  },
  mutations: {
    nav_status(state) {
      /* 左侧导航展开 */
      state.navStatus = !state.navStatus;
    },
    tab_status(state) {
      /* 路由tab展开 */
      state.tabStatus = !state.tabStatus;
    },
    saveTagsData(state, data) {
      state.tagsStoreList = data;
      sessionStorage.setItem("Tags", data);
    },
    /* 工作人员数据 */
    setStaff(state, data) {
      state.staff = data;
    },
    /* 充值界面 */
    setPay(state, data) {
      state.payStatus = data;
    }
  },
  actions: {},
  modules: {
    order,
    tabData,
    navmenu,
    publics,
  },
});
