import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";


// import echarts from 'echarts'
import * as echarts from 'echarts'

import theme from '../node_modules/echarts/theme/walden';
echarts.registerTheme('theme', theme);

import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';


import { dev, axios,isToken,minutes,nums, pageStatus,nums_s } from "../public/js/common";
Vue.prototype.$dev = dev;
Vue.prototype.$axios = axios;
Vue.prototype.$isToken = isToken;
Vue.prototype.$minutes = minutes;
Vue.prototype.$pageStatus = pageStatus;
Vue.prototype.$nums = nums;
Vue.prototype.$nums_s = nums_s;
Vue.prototype.$echarts = echarts;

Vue.prototype.$table = function(obj){
  return this.$store.state.headerClolor[obj];
}

Vue.prototype.$qq = null;


Vue.use(ElementUI, VueQuillEditor);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  // console.log(to)
  let token = sessionStorage.getItem("token");
  if (to.path != "/" && !token) {
    next("/");
  } else {
    next();
  }
});
