import login from "@/views/login.vue";
export default [
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      name: "登录",
    },
  },
];
