import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

/* 自动扫描添加路由 */
let routes = [];
const routerContext = require.context('/', true, /index\.js$/)
routerContext.keys().forEach(route=>{
  if (route.startsWith('./index')) {
    return
  }
  const routerModule = routerContext(route)
  // 兼容 import export 和 require module.export 两种规范
  routes = [...routes, ...(routerModule.default || routerModule)]
})

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: routes
})

export default router;




// Vue.use(VueRouter);

// /* 
// meta: {
//   name: "挂单收银",
// }
// 路由tab显示的名称同时也是title标题名称
// */
// const routes = [
  

  
 

  
  
  
  
// ];

// const router = new VueRouter({
//   routes,
// });

// export default router;
