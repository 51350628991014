/* api接口地址 */
export function dev() {
  return "http://testeas.xiaohuadou.cn/wholesale/api/";
}

/* 
  接口地址
   http://testeas.xiaohuadou.cn/wholesale/api/app/meiye/yue/detail     
   https://wholesale.xiaohuadou.cn/api/app/meiye/yue/detail 
*/
/* axios 请求 */

import Axios from "axios";
export async function axios(arr) {
  /* 加载动画 默认加载 */
  let loading = null;
  if (!arr.loading) {
    loading = this.$loading({
      lock: true,
      text: "请稍等，马上就好了...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.3)",
    });
  }

  /* 获取token */
  let token = sessionStorage.getItem("token") || "";

  /* 如果没有传入数据，默认为一个空对象 */
  if (!arr.data) {
    arr.data = {};
  }
  /* 添加token */
  if (token) {
    arr.data.token = token;
  }
  // baseURL: arr.href.includes("http") ? arr.href : dev() + arr.href,
  let axios = await new Promise((resolve, reject) => {
    // let urls = arr.href.replace(/\/api/,'');
    let url =
      process.env.NODE_ENV === "development"
        ? arr.href
        : "/wholesale" + arr.href;

    Axios({
      method: arr.method || "POST",
      url: url,
      timeout: arr.timeout || 10000000000000000,
      data: arr.data || {},
      headers: arr.headers || {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res.data.data)
        /* 关闭加载动画 */
        if (!arr.loading) {
          loading.close();
        }
        if (res && res.data) {
          if (res.data.data && res.data.data.successful != 0) {
            resolve(res.data);
          } else {
            reject();
            this.$message.error(res.data.data.message);
          }
        }
      })
      .catch((err) => {
        reject(err);
        /* 关闭加载动画 */
        if (!arr.loading) {
          loading.close();
        }
        if (err.response.data.message.includes("登录")) {
          this.$router.replace({
            path: "/",
          });
        } else {
          this.$message.error(err.response.data.message);
        }
      });
  });

  return axios;
}

/* 判断是否存在token */
export function isToken() {
  let t = sessionStorage.getItem("token");
  if (!t) {
    this.$router.replace({
      path: "/",
    });
  }
}

/* 页数一页不显示 */
export function pageStatus(obj) {
  let d = obj - 0;
  if (d > 15) {
    return [true,15];
  } else {
    return false;
  }
}

/* 计算分钟数 */
export function minutes(s, e) {
  // console.log(s, e);
  // let t = s.split(" ")[1];
  // let d = e.split(" ")[1];

  // t = t.replace(/:/g, "") - 0;
  // d = d.replace(/:/g, "") - 0;

  // let num = Math.ceil((d - t) / 100 / 60) * 30;
  let num = (new Date(e).getTime() - new Date(s).getTime()) / 60 / 1000;
  // console.log(num)
  return num;
  // return num;
}

export function nums(obj, dom, type) {
  let num = obj - 0; //总数
  // num = (num < 0) ? (num * -1) : num;
  let step = num / (1000 / 50); //每30ms增加的数值
  let count = 0; //计数器
  let timer = null; //计时器
  let initial = 0;
  let text = document.querySelector(dom); //dom
  if (!text) {
    return "0.00";
  }
  if (num < 1) {
    text.innerHTML = obj;
    return false;
  }
  clearInterval(timer);
  timer = setInterval(() => {
    count += step;
    if (count >= num) {
      clearInterval(timer);
      count = num;
    }
    //t未发生改变的话就直接返回
    let t = Math.floor(count);
    if (t == initial) return;
    initial = t;
    if (type) {
      text.innerHTML =
        initial < 1000000
          ? initial.toFixed(2)
          : (initial / 10000).toFixed(2) + "万";
    } else {
      text.innerHTML = initial.toFixed(2);
    }
  }, 50);
}

export function nums_s(obj,obj1, dom, type) {
  let num = obj - 0; //总数
  let nums = obj-obj1 < 0 ? (obj-obj1)*-1 : obj-obj1;
  // console.log(obj,obj1)
  // num = (num < 0) ? (num * -1) : num;
  let step =nums / (1000 / 50); //每30ms增加的数值
  let count = nums; //计数器
  let timer = null; //计时器
  let initial = nums;
  let text = document.querySelector(dom); //dom
  if (!text) {
    return "0.00";
  }
  if (num < 1) {
    text.innerHTML = obj;
    return false;
  }
  clearInterval(timer);
  timer = setInterval(() => {
    count += step;
    if (count >= num) {
      clearInterval(timer);
      count = num;
    }

    // console.log(obj,obj1)

    //t未发生改变的话就直接返回
    let t = count.toFixed(2);
    if (t == initial) return;
    initial = t - 0;
    if (type) {
      text.innerHTML =
        initial < 1000000
          ? initial.toFixed(0)
          : (initial / 10000).toFixed(0) + "万";
    } else {
      text.innerHTML = initial.toFixed(2);
    }
  }, 50);
}

