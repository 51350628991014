export default [
  {
    path: "/order",
    name: "order",
    component: () => import("@/views/orders/order.vue"),
    meta: {
      name: "订单列表",
    },
  },
  {
    path: "/writeoff",
    name: "writeoff",
    component: () => import("@/views/orders/writeoff.vue"),
    meta: {
      name: "快速核销",
    },
  },
  {
    path: "/wei",
    name: "wei",
    component: () => import("@/views/orders/wei.vue"),
    meta: {
      name: "微店订单",
    },
  },
];
