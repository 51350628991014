/* 
meta: {
  name: "挂单收银",
}
路由tab显示的名称同时也是title标题名称
*/
export default [
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/system/index.vue"),
    meta: {
      name: "系统首页",
    },
  },
];
