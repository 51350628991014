/* 首页订单 */

export default {
  state: {
    orderArr: [
      // {
      //   id: 0,
      //   order: "00001",
      //   name: "刘晓明",
      //   data: [
      //     {
      //       id: 0,
      //       name: "洗发",
      //       people: [
      //         { id: 0, name: "小明", num: "22" },
      //         { id: 1, name: "张三", num: "44" },
      //       ],
      //       pirce: "888",
      //       type: 0,
      //       Newpirce: "",
      //       oldpirce: "",
      //       discount: "",
      //       other: "",
      //     },
      //   ],
      //   pirce: "8888",
      //   other: "333333333",
      //   time: "20:00",
      //   start_time: "1593756684625",
      //   end_time: "1593759292253",
      //   time_type: 0,
      //   source: "",
      //   customer: "",
      //   customerNum: "",
      //   status: 1,
      //   gerge: false,
      //   gergrStatus: false,
      // },
      // {
      //   id: 1,
      //   order: "00002",
      //   name: "刘晓明1",
      //   data: [
      //     {
      //       id: 0,
      //       name: "洗发",
      //       people: [{ id: 0, name: "李四", num: "34" }],
      //       pirce: "888",
      //       type: 0,
      //       Newpirce: "",
      //       oldpirce: "",
      //       discount: "",
      //       other: "",
      //     },
      //   ],
      //   pirce: "8888",
      //   other: "",
      //   time: "20:00",
      //   start_time: "1593692458228",
      //   end_time: "1593759292253",
      //   time_type: 1,
      //   source: "",
      //   customer: "",
      //   customerNum: "",
      //   status: 2,
      //   gerge: false,
      //   gergrStatus: false,
      // },
      // {
      //   id: 2,
      //   order: "00003",
      //   name: "刘晓明2",
      //   data: [
      //     {
      //       id: 0,
      //       name: "洗发",
      //       people: [{ id: 0, name: "刘皇叔", num: "67" }],
      //       pirce: "888",
      //       type: 0,
      //       Newpirce: "",
      //       oldpirce: "",
      //       discount: "",
      //       other: "",
      //     },
      //   ],
      //   pirce: "8888",
      //   other: "",
      //   time: "20:00",
      //   start_time: "1593692458228",
      //   end_time: "1593759292253",
      //   time_type: 1,
      //   source: "",
      //   customer: "",
      //   customerNum: "",
      //   status: 3,
      //   gerge: false,
      //   gergrStatus: false,
      // },
      // {
      //   id: 3,
      //   order: "00004",
      //   name: "刘晓明3",
      //   data: [
      //     {
      //       id: 0,
      //       name: "洗发",
      //       people: [{ id: 0, name: "关二哥", num: "99" }],
      //       pirce: "888",
      //       type: 0,
      //       Newpirce: "",
      //       oldpirce: "",
      //       discount: "",
      //       other: "",
      //     },
      //   ],
      //   pirce: "8888",
      //   other: "",
      //   time: "20:00",
      //   start_time: "1593692458228",
      //   end_time: "1593759292253",
      //   time_type: 1,
      //   source: "",
      //   customer: "",
      //   customerNum: "",
      //   status: 0,
      //   gerge: false,
      //   gergrStatus: false,
      // },
      // {
      //   id: 4,
      //   order: "00005",
      //   name: "",
      //   data: [
      //     {
      //       id: 0,
      //       name: "洗发",
      //       people: [{ id: 0, name: "张三丰", num: "6666" }],
      //       pirce: "888",
      //       type: 0,
      //       Newpirce: "",
      //       oldpirce: "",
      //       discount: "",
      //       other: "",
      //     },
      //   ],
      //   pirce: "8888",
      //   other: "",
      //   time: "20:00",
      //   start_time: "1593692458228",
      //   end_time: "1593759292253",
      //   time_type: 1,
      //   source: "",
      //   customer: "",
      //   customerNum: "",
      //   status: 0,
      //   gerge: false,
      //   gergrStatus: false,
      // },
    ],
    keyShow: false, //数字键盘,
    bill: false, //开单
    wei: false, //显示微店核销的内容
    selectVip: false, //显示搜索会员
    user: [], //订单选中的用户
    vipInfo: false, //显示会员卡信息
    vipInfoData: [], //会员卡信息
    newOrder: {}, //新建的订单数据
    peopelStatus: false, //显示选择工作人员
    loadorder: {}, //开单的数据（等待确认的订单）,
    priceKey: false, //修改订单价格的数字键盘
    others: false, //显示订单备注
    pay: false, //结账支付界面
  },
  mutations: {
    undateTimeStatus(state, val) {
      /* 结束计时 */
      state.orderArr[val].time_type = 0;
      state.orderArr[val].end_time = new Date().getTime();
    },
    /* 显示数字键盘 */
    keyShowS(state, val) {
      state.keyShow = val;
    },
    /* 显示开单 */
    billShowS(state, val) {
      state.bill = val;
    },
    /* 显示微店核销的内容 */
    weiShowS(state, val) {
      state.wei = val;
    },
    /* 显示搜索会员 */
    selectVipShows(state, val) {
      state.selectVip = val;
    },
    /* 选中的用户 */
    setUser(state, val) {
      console.log('选中的会员用户：', val);
      state.user = val;
    },
    /* 显示会员卡信息 */
    vipInfos(state, val) {
      state.vipInfo = val;
    },
    /* 会员卡信息 */
    showVipInfo(state, val) {
      state.vipInfoData = val;
    },
    /* 新建的订单数据 */
    newOrders(state, val) {
      state.newOrder = val;
    },
    /* 显示选择工作人员 */
    peopelStatusShow(state, val) {
      state.peopelStatus = val;
    },
    /* 修改等待确认的订单数据 */
    SetLoadOrder(state, val) {
      state.loadorder = val;
    },
    /* 显示修改价格数字键盘 */
    priceKeyStatus(state, val) {
      state.priceKey = val;
    },
    /* 挂单数据 */
    setDataOrder(state, val){
      // console.log(val,111111)
      state.orderArr = val;
    },
    /* 修改订单数据 */
    updateOrders(state, val) {
      state.orderArr.push(val);
    },
    /* 显示订单备注 */
    othersStatus(state, val) {
      state.others = val;
    },
    /* 显示支付结账界面 */
    payStatus(state, val) {
      state.pay = val;
    },
  },
  actions: {},
  getters: {},
};
